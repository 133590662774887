import React, { useState, useEffect } from 'react';
import { useSession, signIn, signOut } from 'next-auth/client';
import { Navigation, SearchPopup, Button, Ad } from '@app/components';
import DefaultProps from '@app/header.json';
import DefaultPropsUAT from '@app/header-uat.json';
import { getCookie, getCookies } from 'cookies-next';

const DefaultPropsContent = process.env.NEXT_PUBLIC_APP_ENVIRONMENT === 'Production' ? DefaultProps : DefaultPropsUAT;

/**
* Search button component
*/
const Search = () => {
  useEffect(() => {
    document.querySelector('.searchBtn')?.addEventListener('click', () => {
      document.querySelector('.searchPopup')?.classList.remove('!tw-hidden');
    })
  })

  return (
    <div>
      <Button id='searchBtn' title={DefaultPropsContent.search.display} btnType='navIcon' classes='searchBtn' />
      <SearchPopup />
    </div>
  )
}

/**
* Account button component
*/
const Account = props => {
  const { display, manageDisplay, signOutDisplay } = DefaultPropsContent.account;
  const [session] = useSession()
  const [open, setOpen] = useState(false);

  const signOutKeycloak = async () => {
    // logout from keycloak with idtoken and redirect to nextauth logout
    // console.log(session)

    await signOut()
    
    const idToken = session.token.idToken;
    const callbackUrl = window.location.href;
    const logoutUrl = `${process.env.NEXT_PUBLIC_KEYCLOAK_ISSUER}/protocol/openid-connect/logout?id_token_hint=${idToken}&post_logout_redirect_uri=${callbackUrl}`;
    window.location.href = logoutUrl;

    setOpen(false)
  }

  // if (typeof window !== "undefined" && session)  {
  //   localStorage.setItem("user ", JSON.stringify(session));
  //   document.cookie = `user = ${JSON.stringify(session?.user)}; path=/;`;
  //   document.cookie = `token = ${JSON.stringify(session?.token)}; path=/;`;
  // }   

  const displayName = (firstName, lastName) => {
    const initials = firstName && lastName ? `${firstName ? firstName.charAt(0) : ''}${lastName ? `${lastName.charAt(0)}` : ''}` : 'NN';
    return initials.toUpperCase();
  }
  /* <Button data-appid={process.env.NEXT_PUBLIC_AUTHSERVER_CLIENTID} title={display} btnType='navIcon' onClick={() => signIn(process.env.NEXT_PUBLIC_AUTHSERVER_CLIENTID, undefined, { prompt: "login", src: 'AsianInvestor' })} /> */
  return (
    (!session ?
      <Button data-appid={process.env.NEXT_PUBLIC_AUTHSERVER_CLIENTID} title={display} btnType='navIcon' onClick={async() => 
        {
          await signIn('keycloak', { callbackUrl: window.location.href })
        } } />
      : <div className='tw-relative'>
        <a onClick={() => setOpen(!open)} className='tw-relative tw-top-1/2 tw-transform tw--translate-y-1/2 tw-transition-colors tw-w-9 tw-h-9 tw-mr-2 tw-rounded-full tw-font-bold tw-text-secondary-light tw-bg-gray-700 tw-flex tw-flex-col tw-flex-wrap tw-justify-center tw-items-center tw-overflow-hidden tw-cursor-pointer | hover:tw-bg-primary-main'>
          {displayName(session.user.firstName, session.user.lastName)}
        </a>
        <div className={open ? undefined : 'tw-hidden'}>
          <div className='tw-fixed tw-top-0 tw-left-0 tw-right-0 tw-bottom-0 tw-z-10 tw-w-full tw-h-full' onClick={() => setOpen(!open)}></div>
          <div className={`tw-absolute tw-top-full ${session.user.hasActiveSubscription ? 'tw--right-4 | xl:tw-right-2 ' : ''}tw-z-10 tw-bg-secondary-dark tw-p-2 tw--mt-1 tw--ml-4 tw-shadow-md tw-flex tw-flex-col tw-flex-wrap tw-justify-items-start | xl:tw--mt-2`}>
            <div className='tw-transition-colors tw-text-white tw-text-sm tw-p-2'>{session.user.email}</div>
            <a href='/account/manage' target="_blank" className='tw-transition-colors tw-font-bold tw-text-white tw-text-sm tw-p-2 | hover:tw-bg-black'>{manageDisplay}</a>
            <a onClick={() => signOutKeycloak()} className='tw-transition-colors tw-font-bold tw-text-white tw-text-sm tw-p-2 tw-cursor-pointer | hover:tw-bg-black'>{signOutDisplay}</a>
            {/* <a onClick={() => window.location.href = "/api/auth/federated-logout"} className='tw-transition-colors tw-font-bold tw-text-white tw-text-sm tw-p-2 tw-cursor-pointer | hover:tw-bg-black'>f:{signOutDisplay}</a> */}
          </div>
        </div>
      </div>
    )
  )
}


/**
* Subscribe button component
*/
const Subscribe = props => {
  const [session] = useSession()
  return !session?.user.hasActiveSubscription ?
    <div>
      <Button title={DefaultPropsContent.subscribe.display} btnType='subscribe' url={DefaultPropsContent.subscribe.url} />
    </div>
    : null
}


/**
* Menu button component
*/
const Menu = props => {
  const [open, setOpen] = useState(false);
  const onClick = () => {
    setOpen(!open);
  }
  return (
    <div>
      <Button title={DefaultPropsContent.menu.display} btnType='navIcon' onClick={onClick} hidden={DefaultPropsContent.menu.hidden} />
      <Navigation onClose={onClick} btnType='xsNav' open={open} />
    </div>
  )
}


/**
* SideNavigation UI component
*/
export const SideNavigation = props => {
  /*   useEffect(async() => {
      const ses = await getSession()
      const csrfToken = await getCsrfToken()
      console.log(ses, csrfToken)
    }, []);
   */
  return (
    <nav className='tw-inline-flex'>
      <Search />
      <Account {...props} />
      <Subscribe {...props} />
      {/* <div id='searchAd'><Ad type='search' googleAd={true} /></div> */}
      <Menu />
    </nav>
  );
}

export default SideNavigation;