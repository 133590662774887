export const SEARCH_START = 0;
export const SEARCH_ROWS = 12;
export const SEARCH_POPUP_ROWS = 5;
export const LOADMORE_ROWS = 12;
export const RELATED_LOADMORE_ROWS = 6;
export const RELATED_MAX_FETCH = 1;
export const MAX_FETCH = 0;
export const ARTICLE_TYPES = [
  {"label":"Article","value":"article"},
  {"label":"Event","value":"event"},
  {"label":"Gallery","value":"gallery"},
  {"label":"Link","value":"link"},
  {"label":"Webinar","value":"webinar"},
  {"label":"Topic","value":"topic"},
  {"label":"Video","value":"video"}
];
export const DEFAULT_ARTICLE_TYPE='article'
export const SORTINGS = [
  {"label":"Relevance","value":"Relevance"},
  {"label":"Latest","value":"Latest"},
  {"label":"Earliest","value":"Earliest"},
]
export const NEWSLETTERS_PER_PAGE = 120;
export const LOCK_REASON = {
  NotLocked: "NotLocked",
  NoSubscription: "NoSubscription",
  TrialEnded: "TrialEnded",
  Trialing: "Trialing",
  DomainBlacklisted: "DomainBlacklisted",
  NotEligibleForTrial: "NotEligibleForTrial"
}

export const SHORTHAND_INDEX = 'data-shorthand='

export const TRIM_REASONS = [LOCK_REASON.NoSubscription, LOCK_REASON.TrialEnded, LOCK_REASON.DomainBlacklisted, LOCK_REASON.NotEligibleForTrial]

export const TYPEFORM_POLLING_ID = false // 'Zo2wRF7V'

export const CUSTOMER_SERVICE_EMAIL_SUBJECT = 'AsianInvestor Subscription Enquiry';
export const CUSTOMER_SERVICE_EMAIL_BODY = encodeURIComponent("Hi Carolyn,\nI'm interested in a AsianInvestor multi-user/Team license, can you share more details with me?");
