import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { signIn } from 'next-auth/client';
import { Button } from '@app/components';
import JsonData from '@app/header.json';
import JsonDataUAT from '@app/header-uat.json';

const JsonDataContent = process.env.NEXT_PUBLIC_APP_ENVIRONMENT === 'Production' ? JsonData : JsonDataUAT;

/**
* Pay Wall UI component
*/
export const PayWall = props => {
  const [display, setDisplay] = useState(true);
  const { paywall, subscribe, account } = JsonDataContent;

  const replaceContents = {
    remainingQuota: Math.max(props.remainingQuota, 0),
    usedQuota: Math.min(props.quota - props.remainingQuota, props.quota),
    quota: props.quota,
  }

  const replaceArray = (string, replaces) => {
    let replaceString = string;
    Object.keys(replaces).map((replace, i) => {
      //console.log(replaces[replace]);
      replaceString = replaceString.replace(`{{${replace}}}`, replaces[replace]);
      //console.log(replaceString);
    })
    return replaceString;
  };

  const LockWindow = ({ message, lockReason }) =>
    <div className='tw-relative tw-grid tw-grid-cols-1 tw-gap-4 tw-max-w-middle tw-bg-primary-deepLight tw-p-4 | sm:tw-gap-6 | md:tw-gap-8 | 2xl:tw-p-8'>
      {lockReason !== 'Trialing' && <div className='tw-absolute tw-bottom-full tw-bg-gradient-to-t tw-from-white tw-w-full tw-h-60' />}
      <header>
        <h5 className='tw-font-headline tw-text-xl tw-font-bold tw-tracking-tighter tw-text-primary-main | sm:tw-text-2xl | lg:tw-text-3xl' dangerouslySetInnerHTML={{__html: message.header}}/>
        <div className='tw-font-body tw-text-base tw-font-bold tw-tracking-tight tw-text-black tw-mt-0.25em | sm:tw-text-lg sm:tw-mt-0.5em | lg:tw-text-xl' dangerouslySetInnerHTML={{__html: message.body}} />
      </header>
      {message.footer && <footer>{message.footer}</footer>}
      {message.remark &&  <div className='tw-font-body tw-text-base tw-font-bold tw-tracking-tight tw-text-black tw-mt-0.25em | sm:tw-text-lg sm:tw-mt-0.5em | lg:tw-text-xl' dangerouslySetInnerHTML={{__html: message.remark}} />}
    </div>

  const LockBar = ({ message }) =>
    display ? <div className='tw-fixed tw-top-0 tw-left-0 tw-w-full tw-z-10 tw-bg-primary-main tw-p-2 tw-overflow-hidden tw-min-h-9 | sm:tw-min-h-10 | lg:tw-min-h-12'>
      <style>{"\
        body {\
          margin-top: 70px;\
        }\
        @media (min-width: 376px) {\
          body {\
            margin-top: 66px;\
          }\
        }\
        @media (min-width: 640px) {\
          body {\
            margin-top: 54px;\
          }\
        }\
        @media (min-width: 1024px) {\
          body {\
            margin-top: 58px;\
          }\
        }\
      "}</style>
      <div className='tw-flex tw-flex-row tw-flex-nowrap tw-items-center tw-justify-center tw-gap-4 tw-pr-9 tw-w-full tw-h-full | sm:tw-pr-10 | lg:tw-px-12'>
        <div className='tw-flex-shrink tw-font-body tw-text-xs tw-leading-1.125em tw-font-bold tw-text-white | sm:tw-text-sm sm:tw-leading-1.125em | lg:tw-text-base lg:tw-leading-1.125em'>{replaceArray(message.banner, replaceContents)}</div>
        <div><Button btnType='box' title={subscribe.display} size='xs' color='primary' outline classes='sm:tw-whitespace-nowrap' url={subscribe.url} /></div>
      </div>
      <div className='tw-absolute tw-top-1/2 tw-right-0 tw-transform tw--translate-y-1/2'><Button btnType='boxIcon' classes='tw-h-24 | sm:tw-h-16 | lg:tw-h-16' onClick={() => setDisplay(false)} title='close' size='sm' color='primary' /></div>
    </div>
      : ''

  let message;

  switch (props.lockReason) {
    case 'TrialEnded':
      if (props.isLoggedIn) {
        message = {
          ...paywall[props.lockReason].login,
          footer: <div className='tw-inline-flex tw-flex-wrap tw-gap-4'>
          <Button btnType='box' title={subscribe.display} url={subscribe.url} />
          <Button btnType='box' title={account.freeTrial} url={`https://${process.env.AUTHSERVER_DOMAIN}${account.freeTrialUrl}`} />
          <Button btnType='box' title={subscribe.display2} url={subscribe.url} color='secondary' />
        </div>
        }
      } else {
        message = {
          ...paywall[props.lockReason].noLogin,
          footer: <div className='tw-inline-flex tw-flex-wrap tw-gap-4'>
            <Button btnType='box' title={account.signInDisplay} onClick={() => signIn('keycloak', { callbackUrl: window.location.href })} />
            {/*<Button btnType='box' title={account.signInDisplay} onClick={() => signIn(process.env.NEXT_PUBLIC_AUTHSERVER_CLIENTID, undefined, { prompt: "login" })} />*/}
            <Button btnType='box' title={account.freeTrial} url={account.registerUrl} />
            <Button btnType='box' title={subscribe.display2} url={subscribe.url} color='secondary' />
          </div>
        }
      }
      return <LockWindow message={message} lockReason={props.lockReason} />
      break;

    case 'NoSubscription':
      message = {
        ...paywall[props.lockReason],
        footer: <div className='tw-inline-flex tw-flex-wrap tw-gap-4'>
          <Button btnType='box' title={subscribe.display} url={subscribe.url} />
          <Button btnType='box' title={account.freeTrial} url={`https://${process.env.AUTHSERVER_DOMAIN}${account.freeTrialUrl}`} />
          <Button btnType='box' title={subscribe.display2} url={subscribe.url} color='secondary' />
        </div>
      };
      return <LockWindow message={message} lockReason={props.lockReason} />
      break;

    case 'DomainBlacklisted':
      message = {
        ...paywall[props.lockReason],
        footer: <Button btnType='box' title={account.addWorkEmailDisplay} url={`https://${process.env.AUTHSERVER_DOMAIN}${account.addWorkEmailUrl}`} />
      };
      return <LockWindow message={message} lockReason={props.lockReason} />
      break;

    case 'Trialing':
      message = {
        ...paywall[props.lockReason],
        footer: <Button btnType='box' title={subscribe.display} url={subscribe.url} />
      };
      return <>
        {/* <LockWindow message={message} lockReason={props.lockReason} /> */}
        <LockBar message={message} />
      </>
      break;

    case 'NotEligibleForTrial':
      message = {
        ...paywall[props.lockReason],
        footer: <div className='tw-inline-flex tw-flex-wrap tw-gap-4'>
          <Button btnType='box' title={subscribe.display} url={account.registerUrl} />
          <Button btnType='box' title={subscribe.display2} url={subscribe.url} color='secondary' />
        </div>
      };
      return <LockWindow message={message} lockReason={props.lockReason} />
      break;

    default:
      return null;
      break;
  }
};

export default PayWall;

PayWall.propTypes = {
  /**
  * Lock reason from 
  */
  lockReason: PropTypes.oneOf(['NotLocked', 'Trialing', 'TrialEnded', 'NoSubscription', 'DomainBlacklisted', 'NotEligibleForTrial']),
  /**
  * Number of articles remaining quota
  */
  remainingQuota: PropTypes.number,
  /**
  * Number of articles quota
  */
  quota: PropTypes.number,
  /**
  * Is logged in
  */
  isLoggedIn: PropTypes.bool,
};

PayWall.defaultProps = {
  lockReason: 'NotLocked',
  remainingQuota: 0,
  quota: 0,
  isLoggedIn: false
}